import React from 'react';
import Lobby from '../Pages/Lobby';
import Form from '../Pages/Form';
import Header from '../Components/Global/Header';
import Footer from '../Components/Global/Footer';
import MainContent from '../Components/Global/MainContent';
import { Modal } from '../Components/Modal';
// import { Video } from '../Components/Video';
import Game from '../Pages/Game';

import Complete from '../Pages/Complete';

const GameWrapper = ({
  players,
  host,
  errorMessage,
  playerNumber,
  joinLobby,
  leaveLobby,
  removePlayer,
  gameStatus,
  lobbyId,
  name,
  setName,
  email,
  setEmail,
  company,
  setCompany,
  eventData,
  updatePlayerstatus,
  startGame,
  optionData,
  startCountdown,
  setStartCountdown,
  timeLeft,
  currentNumbers,
  modalOpen,
  setModalOpen,
  setErrorMessage,
  nextLevel,
  setGameStatus,
  scoreTimer,
  incorrectAnswer,
  currentLevel,
  rank,
  correctAnswer,
}) => {
  const gameComponents = () => {
    switch (gameStatus) {
      case 'not-started':
        return (
          <Form
            joinLobby={joinLobby}
            email={email}
            setEmail={setEmail}
            name={name}
            setName={setName}
            eventData={eventData}
            optionData={optionData}
            company={company}
            setCompany={setCompany}
          />
        );
      case 'lobby':
        return (
          <Lobby
            lobbyId={lobbyId}
            leaveLobby={leaveLobby}
            removePlayer={removePlayer}
            players={players}
            playerNumber={playerNumber}
            host={host}
            optionData={optionData}
            gameStatus={gameStatus}
            updatePlayerstatus={updatePlayerstatus}
            startGame={startGame}
            setErrorMessage={setErrorMessage}
            setModalOpen={setModalOpen}
          />
        );
      case 'in-progress':
        return (
          <Game
            startCountdown={startCountdown}
            setStartCountdown={setStartCountdown}
            timeLeft={timeLeft}
            currentNumbers={currentNumbers}
            nextLevel={nextLevel}
            playerNumber={playerNumber}
            setGameStatus={setGameStatus}
            scoreTimer={scoreTimer}
            incorrectAnswer={incorrectAnswer}
            correctAnswer={correctAnswer}
            lobbyId={lobbyId}
            currentLevel={currentLevel}
          />
        );
      case 'complete':
        return (
          <Complete
            scoreTimer={scoreTimer}
            timeLeft={timeLeft}
            rank={rank}
            name={name}
            playerNumber={playerNumber}
            optionData={optionData}
          />
        );
      default:
        return (
          <Form
            joinLobby={joinLobby}
            email={email}
            setEmail={setEmail}
            name={name}
            eventData={eventData}
            setName={setName}
            optionData={optionData}
            company={company}
            setCompany={setCompany}
          />
        );
    }
  };

  return (
    <>
      {/* <Video layer="bg" /> */}
      <MainContent>
        <Header
          playerNumber={playerNumber}
          leaveLobby={leaveLobby}
          gameStatus={gameStatus}
          currentLevel={currentLevel}
          rank={rank}
        />
        {gameComponents()}
        {modalOpen && <Modal message={errorMessage} setModalOpen={setModalOpen} />}
        <Footer />
      </MainContent>
      {/* <Video layer="fg" /> */}
    </>
  );
};

export default React.memo(GameWrapper);
