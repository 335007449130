// import React, { useState, useEffect } from 'react';

export default function SvgIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: 'absolute',
        width: 0,
        height: 0,
      }}
      aria-hidden="true"
    >
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" id="tick">
        <path fill="currentColor" d="M158.7 3.4 78.7 142l-52-30-20 34.6 86.6 50 100-173.2z"></path>
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 200 200" id="tickblack">
        <path fill="black" d="M158.7 3.4 78.7 142l-52-30-20 34.6 86.6 50 100-173.2z"></path>
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" id="cross">
        <path
          fill="currentColor"
          d="M149.5 22.2 100 71.7 50.5 22.2 22.2 50.5 71.7 100l-49.5 49.5 28.3 28.3 49.5-49.5 49.5 49.5 28.3-28.3-49.5-49.5 49.5-49.5z"
        ></path>
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" id="chevron-left">
        <path fill="currentColor" d="M121.3 15.1 36.4 100l84.9 84.9 28.3-28.3L93 100l56.6-56.6z"></path>
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108 46" id="brit-logo">
        <path
          fill="currentColor"
          d="M107 2v5.2h-7.7v21.7h-5.9V7.3h-7.7V2H107zM73.3 2h-5.9v26.9h5.9V2zM22.1 21.4c0 5.6-5.3 7.5-10.1 7.5H1V2h10c4.7 0 9.9 1 9.9 6.8 0 3-1.9 5.1-4.6 5.9v.1c3.5.5 5.8 3.1 5.8 6.6zM6.9 12.8h4.3c1.8 0 3.7-.8 3.7-2.9C15 7.6 12.8 7 11 7H7v5.8zm9.3 8c0-2.5-2.9-3-4.8-3H6.9v6.1h5.3c1.9 0 4-.8 4-3.1zm23 8.1h-5.9V2h10.4c5.5 0 10.2 1.9 10.2 8.2 0 3.8-2 6.7-5.8 7.4L54.9 29h-7.1l-5.6-10.8h-2.9v10.7zm0-15.7h3.5c2.2 0 5-.1 5-3 0-2.7-2.4-3.1-4.6-3.1h-3.9v6.1zM26.6 44h54.3v-6H26.6v6z"
        ></path>
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 30" id="chevron-nav-right">
        <path
          fill="currentColor"
          d="M15.3 14.3 2.2 1.2C1.8.8 1.1.8.7 1.2S.3 2.3.7 2.7L13.1 15 .7 27.4c-.4.4-.4 1.1 0 1.5s1.1.4 1.5 0l13.1-13.1c.4-.5.4-1.1 0-1.5z"
        ></path>
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 30" id="chevron-nav-left">
        <path
          fill="currentColor"
          d="m.7 15.7 13.1 13.1c.4.4 1.1.4 1.5 0 .4-.4.4-1.1 0-1.5L2.9 15 15.3 2.6c.4-.4.4-1.1 0-1.5-.4-.4-1.1-.4-1.5 0L.7 14.3c-.4.4-.4 1 0 1.4z"
        ></path>
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 323" id="code-shape-01">
        <path
          fill="currentColor"
          d="M65 9H0V0h65v9zm0 1.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zM65 54H0v9h65v-9zm0 11H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 11H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm130-54v9h65v-9h-65zm0 19.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm-65 2H65v9h65v-9zm0 10.8H65v9h65v-9zm0 10.8H65v9h65v-9zm0 10.8H65v9h65v-9zm-65 19.8h65v-9H65v9zm65 1.8H65v9h65v-9zm65-45h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm-65 11h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9z"
        ></path>
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 323" id="code-shape-02">
        <path
          fill="currentColor"
          d="M130 74H65v-9h65v9zm0 1.8H65v9h65v-9zm0 10.8H65v9h65v-9zm0 10.8H65v9h65v-9zm0 10.8H65v9h65v-9zM65 128h65v-9H65v9zM65 0H0v9h65V0zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zM65 54H0v9h65v-9zm65-54v9h130V0H130zm0 19.8h130v-9H130v9zm0 10.8h130v-9H130v9zm0 10.8h130v-9H130v9zm0 10.8h130v-9H130v9zm0 10.8h130v-9H130v9zm-65 67H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 20h195v-9H65v9zm0 10.8h195v-9H65v9zm0 10.8h195v-9H65v9zm0 10.8h195v-9H65v9zm0 10.8h195v-9H65v9zm0 10.8h195v-9H65v9zm0 11h195v-9H65v9zm0 10.8h195v-9H65v9zm0 10.8h195v-9H65v9zm0 10.8h195v-9H65v9zm0 10.8h195v-9H65v9zm0 10.8h195v-9H65v9z"
        ></path>
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 323" id="code-shape-03">
        <path
          fill="currentColor"
          d="M130 74H0v-9h130v9zm0 1.8H0v9h130v-9zm0 10.8H0v9h130v-9zm0 10.8H0v9h130v-9zm0 10.8H0v9h130v-9zm0 10.8H0v9h130v-9zM0 269h130v-9H0v9zm0 10.8h130v-9H0v9zm0 10.8h130v-9H0v9zm0 10.8h130v-9H0v9zm0 10.8h130v-9H0v9zM0 323h130v-9H0v9zM130 0v9h130V0H130zm0 19.8h130v-9H130v9zm0 10.8h130v-9H130v9zm0 10.8h130v-9H130v9zm0 10.8h130v-9H130v9zm0 10.8h130v-9H130v9zm0 141h130v-9H130v9zm0 10.8h130v-9H130v9zm0 10.8h130v-9H130v9zm0 10.8h130v-9H130v9zm0 10.8h130v-9H130v9zm0 10.8h130v-9H130v9zm0-119h130v-9H130v9zm0 10.8h130v-9H130v9zm0 10.8h130v-9H130v9zm0 10.8h130v-9H130v9zm0 10.8h130v-9H130v9zm0 10.8h130v-9H130v9zm-65 2H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9z"
        ></path>
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 323" id="code-shape-04">
        <path
          fill="currentColor"
          d="M130 74H0v-9h130v9zm0 1.8H0v9h130v-9zm0 10.8H0v9h130v-9zm0 10.8H0v9h130v-9zm0 10.8H0v9h130v-9zm0 10.8H0v9h130v-9zM65 269h130v-9H65v9zm0 10.8h130v-9H65v9zm0 10.8h130v-9H65v9zm0 10.8h130v-9H65v9zm0 10.8h130v-9H65v9zm0 10.8h130v-9H65v9zM195 0H65v9h130V0zm0 10.8H65v9h130v-9zm0 10.8H65v9h130v-9zm0 10.8H65v9h130v-9zm0 10.8H65v9h130v-9zm0 10.8H65v9h130v-9zm-65 141H0v9h130v-9zm0 10.8H0v9h130v-9zm0 10.8H0v9h130v-9zm0 10.8H0v9h130v-9zm0 10.8H0v9h130v-9zm0 10.8H0v9h130v-9zm65-45h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0-193v9h65v-9h-65zm0 19.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9z"
        ></path>
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 323" id="code-shape-05">
        <path
          fill="currentColor"
          d="M65 260h130v9H65v-9zm0 19.8h130v-9H65v9zm0 10.8h130v-9H65v9zm0 10.8h130v-9H65v9zm0 10.8h130v-9H65v9zm0 10.8h130v-9H65v9zM195 0H65v9h130V0zm0 10.8H65v9h130v-9zm0 10.8H65v9h130v-9zm0 10.8H65v9h130v-9zm0 10.8H65v9h130v-9zm0 10.8H65v9h130v-9zm0 150h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0-193v9h65v-9h-65zm0 19.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 11h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm-130 2H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0-184H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 11H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9z"
        ></path>
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 323" id="code-shape-06">
        <path
          fill="currentColor"
          d="M130 260h130v9H130v-9zm0 19.8h130v-9H130v9zm0 10.8h130v-9H130v9zm0 10.8h130v-9H130v9zm0 10.8h130v-9H130v9zm0 10.8h130v-9H130v9zm0-128H65v9h65v-9zm0 10.8H65v9h65v-9zm0 10.8H65v9h65v-9zm0 10.8H65v9h65v-9zm-65 19.8h65v-9H65v9zm65 1.8H65v9h65v-9zM65 130H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zM130 0H0v9h130V0zm0 10.8H0v9h130v-9zm0 10.8H0v9h130v-9zm0 10.8H0v9h130v-9zm0 10.8H0v9h130v-9zm0 10.8H0v9h130v-9zm65 150h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0-193h-65v9h65v-9zm0 10.8h-65v9h65v-9zm0 10.8h-65v9h65v-9zm0 10.8h-65v9h65v-9zm0 10.8h-65v9h65v-9zM130 128h65v-9h-65v9zm65 2v9h65v-9h-65zm0 19.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zM65 65H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9z"
        ></path>
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 323" id="code-shape-07">
        <path
          fill="currentColor"
          d="M130 204H65v-9h65v9zm0 1.8H65v9h65v-9zm0 10.8H65v9h65v-9zm0 10.8H65v9h65v-9zm-65 19.8h65v-9H65v9zm65 1.8H65v9h65v-9zm0 20h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zM0 269h65v-9H0v9zm0 10.8h65v-9H0v9zm0 10.8h65v-9H0v9zm0 10.8h65v-9H0v9zm0 10.8h65v-9H0v9zM0 323h65v-9H0v9zM130 65H65v9h65v-9zm0 10.8H65v9h65v-9zm0 10.8H65v9h65v-9zm0 10.8H65v9h65v-9zm0 10.8H65v9h65v-9zm0 10.8H65v9h65v-9zm65-54v9h65v-9h-65zm0 19.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm-65 11h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm-65-63H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zM195 0h-65v9h65V0zm0 10.8h-65v9h65v-9zm0 10.8h-65v9h65v-9zm0 10.8h-65v9h65v-9zm0 10.8h-65v9h65v-9zm0 10.8h-65v9h65v-9z"
        ></path>
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 323" id="code-shape-08">
        <path
          fill="currentColor"
          d="M130 139H65v-9h65v9zm0 1.8H65v9h65v-9zm0 10.8H65v9h65v-9zm0 10.8H65v9h65v-9zm0 10.8H65v9h65v-9zm0 10.8H65v9h65v-9zM195 0H65v9h130V0zm0 10.8H65v9h130v-9zm0 10.8H65v9h130v-9zm0 10.8H65v9h130v-9zm0 10.8H65v9h130v-9zm0 10.8H65v9h130v-9zM0 269h130v-9H0v9zm0 10.8h130v-9H0v9zm0 10.8h130v-9H0v9zm0 10.8h130v-9H0v9zm0 10.8h130v-9H0v9zM0 323h130v-9H0v9zM130 65H65v9h65v-9zm0 10.8H65v9h65v-9zm0 10.8H65v9h65v-9zm0 10.8H65v9h65v-9zm0 10.8H65v9h65v-9zm0 10.8H65v9h65v-9zm-65 76v9h195v-9H65zm0 19.8h195v-9H65v9zm0 10.8h195v-9H65v9zm0 10.8h195v-9H65v9zm0 10.8h195v-9H65v9zm0 10.8h195v-9H65v9z"
        ></path>
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 323" id="code-shape-09">
        <path
          fill="currentColor"
          d="M195 130h65v9h-65v-9zm0 19.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 11h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0-184h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zM0 0v9h260V0H0zm0 19.8h260v-9H0v9zm0 10.8h260v-9H0v9zm0 10.8h260v-9H0v9zm0 10.8h260v-9H0v9zM0 63h260v-9H0v9zm130 67H0v9h130v-9zm0 10.8H0v9h130v-9zm0 10.8H0v9h130v-9zm0 10.8H0v9h130v-9zm0 10.8H0v9h130v-9zm0 10.8H0v9h130v-9zM0 269h195v-9H0v9zm0 10.8h195v-9H0v9zm0 10.8h195v-9H0v9zm0 10.8h195v-9H0v9zm0 10.8h195v-9H0v9zM0 323h195v-9H0v9z"
        ></path>
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 323" id="code-shape-10">
        <path
          fill="currentColor"
          d="M260 0v9H0V0h260zM0 19.8h260v-9H0v9zm0 10.8h260v-9H0v9zm0 10.8h260v-9H0v9zm0 10.8h260v-9H0v9zM0 63h260v-9H0v9zm0 206h260v-9H0v9zm0 10.8h260v-9H0v9zm0 10.8h260v-9H0v9zm0 10.8h260v-9H0v9zm0 10.8h260v-9H0v9zM0 323h260v-9H0v9zM195 65H65v9h130v-9zM65 84.8h130v-9H65v9zm0 10.8h130v-9H65v9zm0 10.8h130v-9H65v9zm0 10.8h130v-9H65v9zm0 10.8h130v-9H65v9z"
        ></path>
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 323" id="code-shape-11">
        <path
          fill="currentColor"
          d="M260 65v9h-65v-9h65zm-65 19.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 76h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zM0 139h260v-9H0v9zm0 10.8h260v-9H0v9zm0 10.8h260v-9H0v9zm0 10.8h260v-9H0v9zm0 10.8h260v-9H0v9zM0 193h260v-9H0v9zm0 76h195v-9H0v9zm0 10.8h195v-9H0v9zm0 10.8h195v-9H0v9zm0 10.8h195v-9H0v9zm0 10.8h195v-9H0v9zM0 323h195v-9H0v9zM195 0H0v9h195V0zm0 10.8H0v9h195v-9zm0 10.8H0v9h195v-9zm0 10.8H0v9h195v-9zm0 10.8H0v9h195v-9zm0 10.8H0v9h195v-9z"
        ></path>
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 323" id="code-shape-12">
        <path
          fill="currentColor"
          d="M195 74h-65v-9h65v9zm-65 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zM65 65H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 11H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm130-45h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm-65 2H65v9h65v-9zm0 10.8H65v9h65v-9zm0 10.8H65v9h65v-9zm0 10.8H65v9h65v-9zm0 10.8H65v9h65v-9zM65 258h65v-9H65v9zm130-54h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zM0 0v9h260V0H0zm0 19.8h260v-9H0v9zm0 10.8h260v-9H0v9zm0 10.8h260v-9H0v9zm0 10.8h260v-9H0v9zM0 63h260v-9H0v9zm0 206h260v-9H0v9zm0 10.8h260v-9H0v9zm0 10.8h260v-9H0v9zm0 10.8h260v-9H0v9zm0 10.8h260v-9H0v9zM0 323h260v-9H0v9z"
        ></path>
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 323" id="code-shape-13">
        <path
          fill="currentColor"
          d="M65 9H0V0h65v9Zm0 1.8H0v9h65v-9Zm0 10.8H0v9h65v-9Zm0 10.8H0v9h65v-9Zm0 10.8H0v9h65v-9ZM65 54H0v9h65v-9Zm0 11H0v9h65v-9Zm0 10.8H0v9h65v-9Zm0 10.8H0v9h65v-9Zm0 10.8H0v9h65v-9Zm0 10.8H0v9h65v-9Zm0 10.8H0v9h65v-9Zm130-54v9h65v-9h-65Zm0 19.8h65v-9h-65v9Zm0 10.8h65v-9h-65v9Zm0 10.8h65v-9h-65v9Zm0 10.8h65v-9h-65v9Zm0 10.8h65v-9h-65v9Zm-130 2H0v9h65v-9Zm0 10.8H0v9h65v-9Zm0 10.8H0v9h65v-9Zm0 10.8H0v9h65v-9Zm0 10.8H0v9h65v-9Zm0 10.8H0v9h65v-9Zm130-45h65v-9h-65v9Zm0 10.8h65v-9h-65v9Zm0 10.8h65v-9h-65v9Zm0 10.8h65v-9h-65v9Zm0 10.8h65v-9h-65v9Zm0 10.8h65v-9h-65v9Zm-65 2H65v9h65v-9Zm0 10.8H65v9h65v-9Zm0 10.8H65v9h65v-9Zm0 10.8H65v9h65v-9Zm-65 19.8h65v-9H65v9Zm65 1.8H65v9h65v-9Zm65-45h65v-9h-65v9Zm0 10.8h65v-9h-65v9Zm0 10.8h65v-9h-65v9Zm0 10.8h65v-9h-65v9Zm0 10.8h65v-9h-65v9Zm0 10.8h65v-9h-65v9Zm-65 11h65v-9h-65v9Zm0 10.8h65v-9h-65v9Zm0 10.8h65v-9h-65v9Zm0 10.8h65v-9h-65v9Zm0 10.8h65v-9h-65v9Zm0 10.8h65v-9h-65v9Z"
        ></path>
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 323" id="code-shape-14">
        <path
          fill="currentColor"
          d="M65 65h65v9H65v-9zm65 10.8H65v9h65v-9zM65 95.6h65v-9H65v9zm0 10.8h65v-9H65v9zm0 10.8h65v-9H65v9zm0 10.8h65v-9H65v9zM65 0H0v9h65V0zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zM65 54H0v9h65v-9zm65-54v9h130V0H130zm0 19.8h130v-9H130v9zm0 10.8h130v-9H130v9zm0 10.8h130v-9H130v9zm0 10.8h130v-9H130v9zm0 10.8h130v-9H130v9zm-65 67H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm130-54h-65v9h65v-9zm0 10.8h-65v9h65v-9zm0 10.8h-65v9h65v-9zm0 10.8h-65v9h65v-9zm0 10.8h-65v9h65v-9zm0 10.8h-65v9h65v-9zM65 204h195v-9H65v9zm0 10.8h195v-9H65v9zm0 10.8h195v-9H65v9zm0 10.8h195v-9H65v9zm0 10.8h195v-9H65v9zm0 10.8h195v-9H65v9zm0 11h195v-9H65v9zm0 10.8h195v-9H65v9zm0 10.8h195v-9H65v9zm0 10.8h195v-9H65v9zm0 10.8h195v-9H65v9zm0 10.8h195v-9H65v9z"
        ></path>
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 323" id="code-shape-15">
        <path
          fill="currentColor"
          d="M130 74H0v-9h130v9zm0 1.8H0v9h130v-9zm0 10.8H0v9h130v-9zm0 10.8H0v9h130v-9zm0 10.8H0v9h130v-9zm0 10.8H0v9h130v-9zM0 269h130v-9H0v9zm0 10.8h130v-9H0v9zm0 10.8h130v-9H0v9zm0 10.8h130v-9H0v9zm0 10.8h130v-9H0v9zM0 323h130v-9H0v9zm130-119h130v-9H130v9zm0 10.8h130v-9H130v9zm0 10.8h130v-9H130v9zm0 10.8h130v-9H130v9zm0 10.8h130v-9H130v9zm0 10.8h130v-9H130v9zm-65-63H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0-110h195v-9H65v9zm0 10.8h195v-9H65v9zm0 10.8h195v-9H65v9zm0 10.8h195v-9H65v9zm0 10.8h195v-9H65v9zm0 10.8h195v-9H65v9zM65 0v9h195V0H65zm0 19.8h195v-9H65v9zm0 10.8h195v-9H65v9zm0 10.8h195v-9H65v9zm0 10.8h195v-9H65v9zM65 63h195v-9H65v9z"
        ></path>
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 323" id="code-shape-16">
        <path
          fill="currentColor"
          d="M260 65v9H130v-9h130zM130 84.8h130v-9H130v9zm0 10.8h130v-9H130v9zm0 10.8h130v-9H130v9zm0 10.8h130v-9H130v9zm0 10.8h130v-9H130v9zM65 269h130v-9H65v9zm0 10.8h130v-9H65v9zm0 10.8h130v-9H65v9zm0 10.8h130v-9H65v9zm0 10.8h130v-9H65v9zm0 10.8h130v-9H65v9zM195 0H65v9h130V0zm0 10.8H65v9h130v-9zm0 10.8H65v9h130v-9zm0 10.8H65v9h130v-9zm0 10.8H65v9h130v-9zm0 10.8H65v9h130v-9zm-65 150h130v-9H130v9zm0 10.8h130v-9H130v9zm0 10.8h130v-9H130v9zm0 10.8h130v-9H130v9zm0 10.8h130v-9H130v9zm0 10.8h130v-9H130v9zm-65-63H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0-184H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9z"
        ></path>
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 323" id="code-shape-17">
        <path
          fill="currentColor"
          d="M65 260h130v9H65v-9zm0 19.8h130v-9H65v9zm0 10.8h130v-9H65v9zm0 10.8h130v-9H65v9zm0 10.8h130v-9H65v9zm0 10.8h130v-9H65v9zm65-119h130v-9H130v9zm0 10.8h130v-9H130v9zm0 10.8h130v-9H130v9zm0 10.8h130v-9H130v9zm0 10.8h130v-9H130v9zm0 10.8h130v-9H130v9zm65-193v9h65v-9h-65zm0 19.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 11h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm-130 2H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0-184H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 11H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zM195 0H0v9h195V0zm0 10.8H0v9h195v-9zm0 10.8H0v9h195v-9zm0 10.8H0v9h195v-9zm0 10.8H0v9h195v-9zm0 10.8H0v9h195v-9z"
        ></path>
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 323" id="code-shape-18">
        <path
          fill="currentColor"
          d="M129.9 203.9h-65v-9h65v9zm0 1.8h-65v9h65v-9zm0 10.8h-65v9h65v-9zm0 10.8h-65v9h65v-9zm0 10.8h-65v9h65v-9zm-65 19.8h65v-9h-65v9zm0-128h-65v9h65v-9zm0 10.8h-65v9h65v-9zm0 10.8h-65v9h65v-9zm0 10.8h-65v9h65v-9zm0 10.8h-65v9h65v-9zm0 10.8h-65v9h65v-9zm65-184H-.1v9h130v-9zm0 10.8H-.1v9h130v-9zm0 10.8H-.1v9h130v-9zm0 10.8H-.1v9h130v-9zm0 10.8H-.1v9h130v-9zm0 10.8H-.1v9h130v-9zm65 150h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0-193h-65v9h65v-9zm0 10.8h-65v9h65v-9zm0 10.8h-65v9h65v-9zm0 10.8h-65v9h65v-9zm0 10.8h-65v9h65v-9zm-65 19.8h65v-9h-65v9zm65 11h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0-193v9h65v-9h-65zm0 19.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm-130 2h-65v9h65v-9zm0 10.8h-65v9h65v-9zm0 10.8h-65v9h65v-9zm0 10.8h-65v9h65v-9zm0 10.8h-65v9h65v-9zm0 10.8h-65v9h65v-9zm0 150h195v-9h-195v9zm0 10.8h195v-9h-195v9zm0 10.8h195v-9h-195v9zm0 10.8h195v-9h-195v9zm0 10.8h195v-9h-195v9zm0 10.8h195v-9h-195v9z"
        ></path>
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 323" id="code-shape-19">
        <path
          fill="currentColor"
          d="M195 204h-65v-9h65v9zm0 1.8h-65v9h65v-9zm0 10.8h-65v9h65v-9zm0 10.8h-65v9h65v-9zm0 10.8h-65v9h65v-9zm0 10.8h-65v9h65v-9zm0 20h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zM65 269h65v-9H65v9zm0 10.8h65v-9H65v9zm0 10.8h65v-9H65v9zm0 10.8h65v-9H65v9zm0 10.8h65v-9H65v9zm0 10.8h65v-9H65v9zm0-258H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm130-54h-65v9h65v-9zm0 10.8h-65v9h65v-9zm0 10.8h-65v9h65v-9zm0 10.8h-65v9h65v-9zm0 10.8h-65v9h65v-9zm0 10.8h-65v9h65v-9zm0 20h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zM65 139h65v-9H65v9zm65 1.8H65v9h65v-9zm-65 19.8h65v-9H65v9zm0 10.8h65v-9H65v9zm0 10.8h65v-9H65v9zm0 10.8h65v-9H65v9zM195 0v9h65V0h-65zm0 19.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9z"
        ></path>
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 323" id="code-shape-20">
        <path
          fill="currentColor"
          d="M130 204H65v-9h65v9zm0 1.8H65v9h65v-9zm0 10.8H65v9h65v-9zm0 10.8H65v9h65v-9zm0 10.8H65v9h65v-9zm0 10.8H65v9h65v-9zM195 0H65v9h130V0zm0 10.8H65v9h130v-9zm0 10.8H65v9h130v-9zm0 10.8H65v9h130v-9zm0 10.8H65v9h130v-9zm0 10.8H65v9h130v-9zm-65 11H65v9h65v-9zm0 10.8H65v9h65v-9zm0 10.8H65v9h65v-9zm0 10.8H65v9h65v-9zm0 10.8H65v9h65v-9zm0 10.8H65v9h65v-9zm-65 11v9h195v-9H65zm0 19.8h195v-9H65v9zm0 10.8h195v-9H65v9zm0 10.8h195v-9H65v9zm0 10.8h195v-9H65v9zm0 10.8h195v-9H65v9zM0 269h195v-9H0v9zm0 10.8h195v-9H0v9zm0 10.8h195v-9H0v9zm0 10.8h195v-9H0v9zm0 10.8h195v-9H0v9zM0 323h195v-9H0v9z"
        ></path>
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 323" id="code-shape-21">
        <path
          fill="currentColor"
          d="M195 204h-65v-9h65v9zm-65 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm65-193h-65v9h65v-9zm-65 19.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zM0 139h260v-9H0v9zm0 10.8h260v-9H0v9zm0 10.8h260v-9H0v9zm0 10.8h260v-9H0v9zm0 10.8h260v-9H0v9zM0 193h260v-9H0v9zm65 76h195v-9H65v9zm0 10.8h195v-9H65v9zm0 10.8h195v-9H65v9zm0 10.8h195v-9H65v9zm0 10.8h195v-9H65v9zm0 10.8h195v-9H65v9zM65 0v9h195V0H65zm0 19.8h195v-9H65v9zm0 10.8h195v-9H65v9zm0 10.8h195v-9H65v9zm0 10.8h195v-9H65v9zM65 63h195v-9H65v9z"
        ></path>
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 323" id="code-shape-22">
        <path
          fill="currentColor"
          d="M259.9-.1v9H-.1v-9h260zM-.1 19.7h260v-9H-.1v9zm0 10.8h260v-9H-.1v9zm0 10.8h260v-9H-.1v9zm0 10.8h260v-9H-.1v9zm0 10.8h260v-9H-.1v9zm0 206h260v-9H-.1v9zm0 10.8h260v-9H-.1v9zm0 10.8h260v-9H-.1v9zm0 10.8h260v-9H-.1v9zm0 10.8h260v-9H-.1v9zm0 10.8h260v-9H-.1v9zm195-258h-130v9h130v-9zm-130 19.8h130v-9h-130v9zm0 10.8h130v-9h-130v9zm0 10.8h130v-9h-130v9zm0 10.8h130v-9h-130v9zm0 10.8h130v-9h-130v9zm0 76h130v-9h-130v9zm0 10.8h130v-9h-130v9zm0 10.8h130v-9h-130v9zm0 10.8h130v-9h-130v9zm0 10.8h130v-9h-130v9zm0 10.8h130v-9h-130v9z"
        ></path>
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 323" id="code-shape-23">
        <path
          fill="currentColor"
          d="M259.9 64.9v9h-65v-9h65zm-65 19.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 76h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm-195 11h260v-9H-.1v9zm0 10.8h260v-9H-.1v9zm0 10.8h260v-9H-.1v9zm0 10.8h260v-9H-.1v9zm0 10.8h260v-9H-.1v9zm0 10.8h260v-9H-.1v9zm65-184h195v-9h-195v9zm0 10.8h195v-9h-195v9zm0 10.8h195v-9h-195v9zm0 10.8h195v-9h-195v9zm0 10.8h195v-9h-195v9zm0 10.8h195v-9h-195v9zm130-193H-.1v9h195v-9zm0 10.8H-.1v9h195v-9zm0 10.8H-.1v9h195v-9zm0 10.8H-.1v9h195v-9zm0 10.8H-.1v9h195v-9zm0 10.8H-.1v9h195v-9z"
        ></path>
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 323" id="code-shape-24">
        <path
          fill="currentColor"
          d="M65 139H0v-9h65v9zm0 1.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm0 10.8H0v9h65v-9zm130-45h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm-65 2H65v9h65v-9zm0 10.8H65v9h65v-9zm0 10.8H65v9h65v-9zm0 10.8H65v9h65v-9zm0 10.8H65v9h65v-9zM65 258h65v-9H65v9zm130-54h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zm0 10.8h65v-9h-65v9zM0 0v9h260V0H0zm0 19.8h260v-9H0v9zm0 10.8h260v-9H0v9zm0 10.8h260v-9H0v9zm0 10.8h260v-9H0v9zM0 63h260v-9H0v9zm195 2H0v9h195v-9zm0 10.8H0v9h195v-9zm0 10.8H0v9h195v-9zm0 10.8H0v9h195v-9zm0 10.8H0v9h195v-9zm0 10.8H0v9h195v-9zM0 269h195v-9H0v9zm0 10.8h195v-9H0v9zm0 10.8h195v-9H0v9zm0 10.8h195v-9H0v9zm0 10.8h195v-9H0v9zM0 323h195v-9H0v9z"
        ></path>
      </symbol>
    </svg>
  );
}
