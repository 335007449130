import ImgLogo from '../img/logo.png';
// import { Video } from '../Components/Video';
import Header from '../Components/Global/Header';
import Footer from '../Components/Global/Footer';
import MainContent from '../Components/Global/MainContent';

const Landing = () => {
  return (
    <>
      {/* <Video layer="bg" /> */}
      <MainContent>
        <Header gameStatus="landing" />
        <div className="section container container-expand text-center">
          <figure className="w-full max-w-[214px] mx-auto">
            <img className="w-full h-auto" src={ImgLogo} alt="logo" />
          </figure>
          <h1 className="text-h2 font-extrabold mt-6 sm:mt-8">welcome</h1>
          <p className="text-xl mt-6 max-w-xs mx-auto">scan the QR code on the big screen to play a new game</p>
        </div>
        <Footer />
      </MainContent>
      {/* <Video layer="fg" /> */}
    </>
  );
};

export default Landing;
