import { useState, useEffect } from 'react';

function useRandomNumbers(currentNumbers, currentLevel) {
  const [randomNumbers, setRandomNumbers] = useState([]);

  useEffect(() => {
    // Generate 12 random numbers
    let newNumbers = [...currentNumbers];
    console.log(currentLevel);
    const numberOfOptions = () => {
      if (currentLevel === 0) {
        return 9;
      } else if (currentLevel === 1) {
        return 12;
      } else if (currentLevel === 2) {
        return 16;
      }
    };

    console.log(numberOfOptions());

    while (newNumbers.length < numberOfOptions()) {
      const newNum = Math.floor(Math.random() * 24) + 1;
      if (!newNumbers.includes(newNum)) {
        newNumbers.push(newNum);
      }
    }
    // Shuffle the array
    for (let i = newNumbers.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newNumbers[i], newNumbers[j]] = [newNumbers[j], newNumbers[i]];
    }
    setRandomNumbers(newNumbers);
  }, [currentLevel, currentNumbers]);

  return randomNumbers;
}

export default useRandomNumbers;
