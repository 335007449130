import { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { SocketContext } from '../Providers/socketProvider';
import { useNavigate, useParams } from 'react-router-dom';
import useTimer from '../Hooks/useTimer';
import { getEventById, getEventData, getOptionsData, updatePlayerInfo } from '../Components/UpdatePlayerInfo';

const SocketWrapper = ({ component }) => {
  const socket = useContext(SocketContext);
  let navigate = useNavigate();
  const { id } = useParams();

  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [players, setPlayers] = useState([]);
  const [host, setHost] = useState(null);
  const [errorMessage, setErrorMessage] = useState({
    heading: 'this app uses essential cookies',
    copy: 'we may also use non-essential cookies with your consent.',
    close: false,
    button: { text: 'ALLOW', link: null },
  });
  const [playerNumber, setPlayerNumber] = useState(null);
  const [lobbyId, setLobbyId] = useState(id);
  const [gameStatus, setGameStatus] = useState('not-started');
  const [timeLeft, setTimeLeft] = useState(0);
  const [startCountdown, setStartCountdown] = useState(false);
  const [currentNumbers, setCurrentNumbers] = useState([]);
  const [modalOpen, setModalOpen] = useState(true);
  const [currentLevel, setCurrentLevel] = useState(0);
  const [rank, setRank] = useState(0);
  const [gameResultSubmitted, setGameResultSubmitted] = useState(false);

  const scoreTimer = useTimer();

  const gameResultSubmittedRef = useRef();
  gameResultSubmittedRef.current = gameResultSubmitted;
  const nameRef = useRef();
  nameRef.current = name;
  const emailRef = useRef();
  emailRef.current = email;
  const companyRef = useRef();
  companyRef.current = company;
  const elapsedTimeRef = useRef();
  elapsedTimeRef.current = scoreTimer.elapsedTime;
  const gameStatusRef = useRef();
  gameStatusRef.current = gameStatus;

  useEffect(() => {
    setLobbyId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (scoreTimer.elapsedTime > 120000) {
      setGameStatus('complete');
    }
  }, [navigate, scoreTimer.elapsedTime, socket]);

  const [optionData, setOptionData] = useState([]);
  const [eventData, setEventData] = useState([]);
  useEffect(() => {
    async function fetchEventData() {
      try {
        const eventData = await getEventData(lobbyId.split('-')[0]);
        const optionApiData = await getOptionsData();

        setEventData(eventData);
        setOptionData(optionApiData[0]);
        console.log(eventData);
        console.log(optionApiData[0]);
      } catch (e) {
        console.log(e);
        setModalOpen(true);
        setErrorMessage({
          heading: `this lobby doesn't exist`,
          copy: 'we may also use non-essential cookies with your consent.',
          close: false,
          button: { text: 'OK', link: '/' },
        });
      }
    }
    if (gameStatus === 'not-started') {
      fetchEventData();
    }
  }, [gameStatus, lobbyId]);

  const joinLobby = (type = 'player') => {
    setErrorMessage(null);
    socket.emit('joinLobby', { name, lobbyId, type, company, email });
  };

  const leaveLobby = (lobbyId) => {
    navigate('/');
  };

  const removePlayer = (playerId) => {
    socket.emit('removePlayer', { lobbyId, playerId });
  };

  const updatePlayerstatus = (lobbyId, playerId, status) => {
    socket.emit('playerStatusUpdated', { lobbyId, playerId, status });
  };

  const startGame = (lobbyId) => {
    socket.emit('startGame', { lobbyId });
  };

  const incorrectAnswer = ({ playerNumber, stage }) => {
    socket.emit('incorrectAnswer', { lobbyId, playerNumber, stage: 0 });
  };

  const correctAnswer = ({ playerNumber, stage }) => {
    socket.emit('correctAnswer', { lobbyId, playerNumber, stage });
  };

  const nextLevel = () => {
    setCurrentLevel(currentLevel + 1);
    var howMany = 2;
    if (process.env.REACT_APP_HOWMANY_GAMES) {
      howMany = process.env.REACT_APP_HOWMANY_GAMES;
    }
    if (currentLevel < howMany) {
      socket.emit('nextLevel', { currentLevel: currentLevel + 1, lobbyId, playerNumber });
    } else {
      scoreTimer.handlePause();
      if (gameStatus !== 'complete') {
        setGameStatus('complete');
        socket.emit('gameComplete', {
          lobbyId,
          playerId: socket.io.engine.id,
          time: elapsedTimeRef.current,
          playerNumber,
        });
      }
    }
  };

  const gameincomplete = useCallback(() => {
    scoreTimer.handlePause();
    setGameStatus('complete');
    socket.emit('gameComplete', { lobbyId, playerId: socket.io.engine.id, time: 9999999999, playerNumber });
  }, [scoreTimer, socket, lobbyId, playerNumber]);

  useEffect(() => {
    socket.on('lobbyFull', () => {
      setErrorMessage({ heading: 'The lobby is full.', copy: null, button: { text: 'OK', link: '/' }, close: false });
      setModalOpen(true);
    });
    socket.on('nameTaken', () => {
      setErrorMessage({
        heading: 'The name is already taken.',
        copy: null,
        button: { text: 'OK', link: '/' },
        close: false,
      });

      setModalOpen(true);
    });

    socket.on('removedFromLobby', () => {
      setErrorMessage({
        heading: 'You have been removed from the lobby by the host.',
        copy: null,
        button: { text: 'OK', link: '/' },
        close: false,
      });

      setModalOpen(true);
    });

    socket.on('noGame', () => {
      setErrorMessage({
        heading: 'Sorry this lobby no longer exists.',
        copy: null,
        button: { text: 'OK', link: '/' },
        close: false,
      });
      setModalOpen(true);
    });

    socket.on('tvDisconnected', () => {
      socket.disconnect();
      resetState();
    });

    // Handle new player joining the lobby
    socket.on('playerJoined', ({ playerId, player, players, host }) => {
      console.log(`-- socket ${playerId} joined the lobby --`);
      const playerInGame = players.some((p) => p.status === 'in-game');
      if (playerInGame) {
        setErrorMessage({
          heading: 'The game has already started.',
          copy: null,
          button: { text: 'OK', link: '/' },
          close: false,
        });
      } else {
        setPlayers(players);
        setHost(host);

        setGameStatus('lobby');

        if (player.id === socket.io.engine.id) {
          setName(player.name);
          setPlayerNumber(player.playerNumber);
        } else {
          if (players.length > 1) {
            const updatedPlayers = players.map((player, index) => ({
              ...player,
              playerNumber: index + 1,
            }));
            setPlayers(updatedPlayers);
          }
        }
      }
    });

    socket.on('playersUpdated', (players) => {
      setPlayers(players);
      console.log(players);
    });

    socket.on('startTimer', ({ duration }) => {
      const d = process.env.REACT_APP_GAMETIME ? process.env.REACT_APP_GAMETIME : duration;
      setTimeLeft(d);
      console.log(';game time = ' + d);
      setGameStatus('in-progress');
      setStartCountdown(true);
    });

    socket.on('numbersGenerated', ({ numbers }) => {
      setCurrentNumbers(numbers);
    });

    socket.on('gameCompleted', ({ rank, time }) => {
      setRank(rank);
      // wpapi stuff here
      console.log('name = ' + nameRef.current);
      console.log('------');
      console.log('email = ' + emailRef.current);
      console.log('------');
      console.log('company = ' + companyRef.current);
      console.log('------');
      console.log('score = ' + elapsedTimeRef.current);
      console.log('------');
      if (!gameResultSubmittedRef.current) {
        setGameResultSubmitted(true);
        gameResultSubmittedRef.current = true;
        updatePlayerInfo({
          email: emailRef.current,
          company: companyRef.current,
          name: nameRef.current,
          lobbyId: lobbyId.split('-')[0],
          score: time,
        });
      }
    });

    socket.on('allGamesCompleted', () => {
      setTimeout(() => {
        socket.disconnect();
        navigate('/');
      }, 10000);
    });

    socket.on('playerLeft', ({ playerId, players, host }) => {
      // Update player numbers

      if (gameStatusRef.current !== 'in-progress') {
        const updatedPlayers = players.map((player, index) => ({
          ...player,
          playerNumber: index + 1,
        }));

        setPlayers(updatedPlayers);
        const updatedPlayer = updatedPlayers.find((player) => player.id === socket.io.engine.id);

        // Update host if necessary
        if (playerId === host) {
          setHost(players.length > 0 ? players[0].id : null);
        }

        // Update player number if necessary
        if (updatedPlayer && updatedPlayer.playerNumber !== playerNumber) {
          setPlayerNumber(updatedPlayer.playerNumber);
        }
      } else {
        setPlayers(players);
      }
    });

    const resetState = () => {
      setName('');
      setPlayers([]);
      setHost(null);
      if (gameStatus !== 'complete') {
        setErrorMessage({
          heading: 'You have been disconnected from the game.',
          copy: null,
          button: { text: 'OK' },
          close: false,
        });
        setModalOpen(true);
      }
      setGameStatus('not-started');
      navigate('/');
      setPlayerNumber(null);
      setLobbyId(id);
    };

    socket.on('connect', () => {
      setGameStatus('not-started');
    });

    socket.on('disconnect', resetState);

    return () => {
      socket.removeAllListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (gameStatus === 'complete' && elapsedTimeRef.current >= 120000) {
      gameincomplete();
    }
  }, [currentLevel, gameStatus, gameincomplete, scoreTimer.elapsedTime]);

  return component({
    name,
    setName,
    email,
    setEmail,
    company,
    setCompany,
    players,
    eventData,
    setPlayers,
    host,
    setHost,
    errorMessage,
    setErrorMessage,
    playerNumber,
    setPlayerNumber,
    joinLobby,
    leaveLobby,
    removePlayer,
    gameStatus,
    setGameStatus,
    lobbyId,
    updatePlayerstatus,
    startGame,
    startCountdown,
    setStartCountdown,
    timeLeft,
    optionData,
    currentNumbers,
    setModalOpen,
    modalOpen,
    nextLevel,
    scoreTimer,
    incorrectAnswer,
    currentLevel,
    rank,
    correctAnswer,
  });
};

export default SocketWrapper;
