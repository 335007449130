import socketio from 'socket.io-client';
import { createContext } from 'react';

export let socket;
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  socket = socketio.connect('http://localhost:3001/');
} else {
  socket = socketio.connect('https://brit-insurance-cyber-game.herokuapp.com/');
}

export const SocketContext = createContext();
