const WPAPI = require('wpapi');

const ep = process.env.REACT_APP_WP_URL || 'https://cms.britcodehacker.co.uk/wp-json';
const wp = new WPAPI({
  endpoint: ep,
  username: process.env.REACT_APP_WP_LOGIN_USER,
  password: process.env.REACT_APP_WP_LOGIN_PASS,
});

wp.player = wp.registerRoute('wp/v2', 'player/(?P<id>\\d+)');
wp.playerAcf = wp.registerRoute('acf/v3', 'player/(?P<id>\\d+)');
wp.eventAcf = wp.registerRoute('acf/v3', 'event/(?P<id>\\d+)');
// wp.event = wp.registerRoute('wp/v2', `event/(?P<id>\\d+)?variable=123`);
wp.options = wp.registerRoute('acf/v3', 'options/mobile_options');
wp.event = wp.registerRoute('kerve/v1', `event/(?P<id>\\d+)?variable=123`);

wp.update_player = wp.registerRoute('kerve/v1', `update_player/`);

console.log(wp.event);

// wp.options = wp.registerRoute('wp/v', 'mobile_options/(?P<id>\\d+)');

const updatePlayer = ({ name, company, email, event_id, score }) =>
  wp.update_player().create({ name, company, email, event_id, score });
const getEventById = (eventId) => wp.event().id(eventId).get();

export async function updatePlayerInfo({ email, company, name, lobbyId, score }) {
  await updatePlayer({ email, company, name, event_id: lobbyId, score });
}

export async function getEventData(lobbyId) {
  const [event] = await Promise.all([getEventById(lobbyId)]);
  return event;
}

export async function getOptionsData() {
  const [options] = await Promise.all([wp.options()]);
  return options;
}
