import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from './Button';
import { Clone } from '../js/components/Clone';

export function Modal({ message, setModalOpen }) {
  const navigate = useNavigate();
  const [closeModal, setCloseModal] = useState(false);
  const callback = () => {
    if (message.button.url) {
      navigate(message.button.url);
    } else if (message.callback) {
      return message.callback();
    } else {
      setCloseModal(true);
      setTimeout(() => {
        setModalOpen(false);
      }, '700');
    }
  };

  const clonedRef = useRef(false);
  const doClone = () => {
    Clone('js-cross-clone', ['ani-glitch-split-2'], ['ani-glitch-split-1']);
  };
  useEffect(() => {
    if (clonedRef.current) return;
    clonedRef.current = true;
    doClone();
  }, []);

  const glitchClass = 'ani-glitch-split-' + Math.floor(Math.random() * (8 - 7 + 1) + 7);

  return (
    <div className="w-full h-screen fixed inset-0 flex justify-center items-center z-50 bg-black/80">
      <div className="container section relative">
        <div className={`${closeModal === true ? glitchClass + ' ' : ''}bg-double-shadow rounded-lg`}>
          <div className="bg-dark p-4 p-8 relative z-10 rounded-lg text-white text-center space-y-4 relative">
            {message.close && (
              <div
                className="w-10 h-10 flex justify-center items-center absolute top-0 right-0 z-10"
                onClick={() => setModalOpen(false)}
              >
                <div className="clone-parent w-6 h-6">
                  <svg className="js-cross-clone w-6 h-6 ani-glitch-split-1 animation-duration-5s">
                    <use xlinkHref="#cross" />
                  </svg>
                </div>
              </div>
            )}
            <h2 className="text-h3 !normal-case">{message.heading}</h2>
            <div>
              <p>{message.copy}</p>
            </div>
            <p>
              <Button
                size="lg"
                glitch="2"
                callback={callback}
                classes="min-w-[80%] mx-auto mt-6"
                title={message.button.text}
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
