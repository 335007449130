import ImgLogo from '../../img/logo.png';
import { useNavigate } from 'react-router-dom';

export default function Header({ playerNumber, gameStatus, leaveLobby, currentLevel, rank }) {
  return (
    <header role="banner" className="w-full relative z-50 mb-[37px]">
      <HeaderInner
        playerNumber={playerNumber}
        leaveLobby={leaveLobby}
        gameStatus={gameStatus}
        currentLevel={currentLevel}
        rank={rank}
      />
    </header>
  );
}

export const HeaderInner = ({ gameStatus, leaveLobby, playerNumber, currentLevel, rank }) => {
  let clr = 'fill-sky';
  if (playerNumber === 1) {
    clr = 'fill-amethyst';
  } else if (playerNumber === 2) {
    clr = 'fill-flamingo';
  } else if (playerNumber === 3) {
    clr = 'fill-carnation';
  }

  if (gameStatus === 'landing') {
    return (
      <>
        <div className="w-full h-[35px] bg-sky"></div>
      </>
    );
  } else {
    return (
      <>
        <svg
          className="w-[1550px] h-[35px] absolute top-0 left-[50%] translate-x-[-50%] z-[51]"
          xmlns="http://www.w3.org/2000/svg"
          xmlSpace="preserve"
          style={{
            enableBackground: 'new 0 0 1550 70',
          }}
          viewBox="0 0 1550 70"
        >
          <path
            className={`${clr}`}
            d="M0-.4v70h642.4c13.8 0 27.6-11.2 30.8-25l1-4.3c4.8-11.7 17.1-20.7 29.4-20.7h143.8c12.3 0 24.6 8.9 29.4 20.7l1 4.3c3.2 13.8 17 25 30.8 25H1550v-70H0z"
          />
        </svg>
        <nav className="text-dark relative z-[52] w-full">
          <div className="container flex justify-between items-center h-[35px]">
            <span className=" w-1/3 uppercase font-bold text-md">
              <HeaderNavLeft gameStatus={gameStatus} playerNumber={playerNumber} />
            </span>
            <figure className="w-1/3 relative top-7">
              <img className="w-20 h-auto mx-auto" src={ImgLogo} alt="logo" />
            </figure>
            <span className="w-1/3 uppercase font-bold text-md flex justify-end ">
              <HeaderNavRight gameStatus={gameStatus} leaveLobby={leaveLobby} currentLevel={currentLevel} rank={rank} />
            </span>
          </div>
        </nav>
      </>
    );
  }
};

export const HeaderNavLeft = ({ gameStatus, playerNumber }) => {
  if (gameStatus === 'not-started') {
    return <>sign up</>;
  } else {
    return <>player {playerNumber}</>;
  }
};

export const HeaderNavRight = ({ gameStatus, leaveLobby, currentLevel, rank }) => {
  const navigate = useNavigate();
  if (gameStatus === 'not-started') {
    return (
      <a onClick={() => navigate('/')} className="flex items-center">
        <svg className="mr-1 w-4 h-4">
          <use xlinkHref="#cross" />
        </svg>
        <span>exit</span>
      </a>
    );
  } else if (gameStatus === 'lobby') {
    return <>stand by</>;
  } else if (gameStatus === 'in-progress') {
    return <>level 0{currentLevel + 1}</>;
  } else if (gameStatus === 'complete') {
    return (
      <>
        {rank}&nbsp;
        {rank !== 'lost' ? 'place' : ''}
      </>
    );
  }
};
