import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import FourOhFour from './FourOhFour';
import Test from './Test';
import { socket, SocketContext } from '../Providers/socketProvider';
import SocketWrapper from '../Wrappers/SocketWrapper';
import GameWrapper from '../Wrappers/GameWrapper';
import Landing from './Landing';

import SvgIcon from '../Components/Global/SvgIcon';

import '../scss/theme.scss';

function App() {
  return (
    <SocketContext.Provider value={socket}>
      <BrowserRouter>
        <SvgIcon />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/test" element={<Test />} />
          <Route exact path="/:id" element={<SocketWrapper component={(props) => <GameWrapper {...props} />} />} />
          <Route path="*" element={<FourOhFour />} />
        </Routes>
      </BrowserRouter>
    </SocketContext.Provider>
  );
}

export default App;
