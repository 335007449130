// import React, { useRef, useLayoutEffect } from 'react';
// import { gsap } from 'gsap';
// import { Button } from '../Components/Button';
// import { Modal } from '../Components/Modal';
// import Countdown from 'react-countdown';

const Test = () => {
  return (
    <ul className="list-none text-white">
      <li className={'shape relative aspect-[66/82]'}>
        <div className="clone-parent">
          <svg className={`shape-svg w-full h-full opacity-0`} preserveAspectRatio="xMinYMid meet">
            <use xlinkHref={`#code-shape-4`} />
          </svg>
          <svg
            className={`shape-svg w-full h-full transition-all } duration-250 ease-out`}
            preserveAspectRatio="xMinYMid meet"
          >
            <use xlinkHref={`#code-shape-4`} />
          </svg>
        </div>
        <div className="w-[60px] h-[60px] bg-double-shadow rounded-full absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[10]">
          <div className="bg-dark rounded-full border-current border-[2px] flex justify-center items-center w-full h-full absolute z-[11]">
            <svg className="w-[30px] h-30px text-white ">
              <use xlinkHref={`#tick`} />
            </svg>
          </div>
        </div>
      </li>
    </ul>
  );
};

export default Test;
