import React, { useEffect, useState } from 'react';
import GameCountdown from '../Components/Countdown';
// import GameTImer from '../Components/GameTImer';
import useRandomNumbers from '../Hooks/useRandomNumbers';

const Game = ({
  startCountdown,
  setStartCountdown,
  currentNumbers,
  nextLevel,
  scoreTimer,
  playerNumber,
  incorrectAnswer,
  correctAnswer,
  currentLevel,
}) => {
  const randomNumbers = useRandomNumbers(currentNumbers, currentLevel);
  const [gridCols, setGridCols] = useState('grid-cols-3');
  const [gridGap, setGridGap] = useState('gap-x-10 gap-y-8');
  const [iconRoundSize, setIconRoundSize] = useState('w-[60px] h-[60px]');
  const [iconSize, setIconSize] = useState('w-[30px] h-[30px]');
  const [stage, setStage] = useState(0);
  const [remainingNumbers, setRemainingNumbers] = useState(currentNumbers);
  const [wrongNumber, setWrongNumber] = useState(null);
  const [rightNumber, setrightNumber] = useState(null);

  var shadow, text, bg;

  if (playerNumber === 1) {
    shadow = 'drop-shadow-amethyst-md';
    text = 'text-amethyst';
    bg = 'bg-amethyst';
  } else if (playerNumber === 2) {
    shadow = 'drop-shadow-flamingo-md';
    text = 'text-flamingo';
    bg = 'bg-flamingo';
  } else if (playerNumber === 3) {
    shadow = 'drop-shadow-carnation-md';
    text = 'text-carnation';
    bg = 'bg-carnation';
  }

  useEffect(() => {
    if (randomNumbers.length === 9) {
      setGridCols('grid-cols-3');
      setGridGap('gap-x-10 gap-y-8');
      setIconRoundSize('w-[60px] h-[60px]');
      setIconSize('w-[30px] h-[30px]');
    } else if (randomNumbers.length === 12) {
      setGridCols('grid-cols-4');
      setGridGap('gap-x-6 gap-y-6');
      setIconRoundSize('w-[46px] h-[46px]');
      setIconSize('w-[23px] h-[23px]');
    } else if (randomNumbers.length === 16) {
      setGridCols('grid-cols-4');
      setGridGap('gap-x-6 gap-y-6');
      setIconRoundSize('w-[46px] h-[46px]');
      setIconSize('w-[23px] h-[23px]');
    }
  }, [randomNumbers.length]);

  useEffect(() => {
    setRemainingNumbers(currentNumbers);
  }, [currentNumbers]);

  const handleSubmitAnswer = (n) => {
    const expectedNumber = remainingNumbers[0];

    if (n === expectedNumber) {
      // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
      // Correct guess
      // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

      // descriptive comment here
      setRemainingNumbers(remainingNumbers.slice(1));
      setStage(stage + 1);
      correctAnswer({ playerNumber, stage });
      setrightNumber(n);
      setTimeout(() => {
        setrightNumber(null);
        if (remainingNumbers.length === 1) {
          setStage(0);
          nextLevel();
          setRemainingNumbers(currentNumbers);
        }
      }, 500);
    } else {
      // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
      // Incorrect guess
      // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
      setWrongNumber(n);
      setTimeout(() => {
        setWrongNumber(null);
      }, 500);
      const first = document.querySelector(`.shape[data-id="${n}"] .shape-svg:first-child`);
      const second = document.querySelector(`.shape[data-id="${n}"] .shape-svg:last-child`);
      first.classList.remove('opacity-0');
      first.classList.add('ani-glitch-split-7');
      second.classList.add('ani-glitch-split-8');
      setTimeout(function () {
        first.classList.add('opacity-0');
        first.classList.remove('ani-glitch-split-7');
        second.classList.remove('ani-glitch-split-8');
      }, Math.floor(Math.random() * 1500) + 400);
      const allIndicators = document.querySelectorAll('.js-stage-progress-indicator');
      allIndicators.forEach((indicator) => {
        indicator.classList.remove(bg);
        indicator.classList.remove('!border-current');
      });
      incorrectAnswer({ playerNumber, stage });
      setRemainingNumbers(currentNumbers);
      setStage(0);
    }
  };

  useEffect(() => {
    if (!startCountdown && !scoreTimer.isRunning) {
      scoreTimer.handleStart();
    }
  }, [scoreTimer, startCountdown]);

  if (startCountdown) {
    return <GameCountdown startCountdown={startCountdown} setStartCountdown={setStartCountdown} />;
  } else {
    return (
      <div className="section container max-w-xs">
        <div className={`${text} section-game-countdown flex flex-col text-center max-w-[240px] mx-auto`}>
          {/* <ul className="flex list-none items-center justify-center mb-4">
            {currentNumbers.map((n, key) => {
              let id = n;

              if (id < 10) {
                id = '0' + id;
              }

              return (
                <li className="aspect-[66/82] w-12" data-id={n} key={key}>
                  <svg className="w-full h-full">
                    <use xlinkHref={`#code-shape-${id}`} />
                  </svg>
                </li>
              );
            })}
          </ul> */}
          {/* <header className="countdown-header font-bold bg-current px-4 uppercase">
            <span className="text-dark">time remaining player {playerNumber}</span>
          </header> */}
          {/* <GameTImer className={text} timeLeft={timeLeft} setGameStatus={setGameStatus} /> */}
        </div>

        {/* <div className={`${text} my-2 sm:my-4 flex justify-center items-center gap-4 sm:my-4`}>
          {currentNumbers.map((n, key) => {
            return (
              <i
                className={`${
                  stage >= key + 1 ? bg + ' border-current ' : ''
                }js-stage-progress-indicator block w-[18px] h-[18px] rounded-full border-mid border text-dark transition-all duration-700 flex justify-center items-center`}
                data-id={key}
                key={key}
              >
                <svg className="w-3 h-3">
                  <use xlinkHref={`#tick`} />
                </svg>
              </i>
            );
          })}
        </div> */}

        <div className="section-sm">
          <ul className={`${gridCols} ${gridGap} grid list-none text-white`}>
            {randomNumbers.map((n, key) => {
              let id = n;

              if (id < 10) {
                id = '0' + id;
              }

              const addRightAnimClass = () => {
                if (rightNumber === n) {
                  return 'drop-shadow-white-lg';
                }
              };

              return (
                <li className={'shape relative'} data-id={n} onClick={() => handleSubmitAnswer(n)} key={key}>
                  <div className="clone-parent aspect-[66/82]">
                    <svg className={`shape-svg w-full h-full opacity-0`} preserveAspectRatio="xMinYMid meet">
                      <use xlinkHref={`#code-shape-${id}`} />
                    </svg>
                    <svg
                      className={`${shadow} shape-svg w-full h-full transition-all ${addRightAnimClass()} duration-250 ease-out`}
                      preserveAspectRatio="xMinYMid meet"
                    >
                      <use xlinkHref={`#code-shape-${id}`} />
                    </svg>
                  </div>
                  <div
                    className={`${iconRoundSize} ${
                      rightNumber === n ? 'opacity-1 scale-1' : 'opacity-0 scale-0'
                    } transition-all bg-double-shadow rounded-full !absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[10]`}
                  >
                    <div className="bg-dark rounded-full border-current border-[2px] flex justify-center items-center w-full h-full absolute z-[11]">
                      <svg className={`${iconSize} text-white`}>
                        <use xlinkHref={`#tick`} />
                      </svg>
                    </div>
                  </div>
                  <div
                    className={`${iconRoundSize} ${
                      wrongNumber === n ? 'opacity-1 scale-1' : 'opacity-0 scale-0'
                    } transition-all bg-double-shadow rounded-full !absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[10]`}
                  >
                    <div className="bg-dark rounded-full border-current border-[2px] flex justify-center items-center w-full h-full absolute z-[11]">
                      <svg className={`${iconSize} text-white`}>
                        <use xlinkHref={`#cross`} />
                      </svg>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
};

export default React.memo(Game);
