import React from 'react';
// import { Clone } from '../js/components/Clone';

export function Button({ size, classes, title, callback = null, glitch = false }) {
  let btnClasses = 'btn uppercase bg-double-shadow';

  // const clonedRef = useRef(false);
  // const doClone = () => {
  //   Clone('js-btn-glitch', ['ani-glitch-split-2'], ['ani-glitch-split-1', 'js-clone']);
  // };
  // useEffect(() => {
  //   if (clonedRef.current) return;
  //   clonedRef.current = true;
  //   doClone();
  // }, []);

  let glitchClass1;
  let glitchClass2;
  if (glitch === '1') {
    glitchClass1 = 'ani-glitch-split-3';
    glitchClass2 = 'ani-glitch-split-4';
  }
  if (glitch === '2') {
    glitchClass1 = 'ani-glitch-split-5';
    glitchClass2 = 'ani-glitch-split-6';
  }

  if (classes) {
    btnClasses = btnClasses + ' ' + classes;
  }
  if (size === 'lg') {
    btnClasses = btnClasses + ' text-h3';
  }
  if (size === 'lg') {
    btnClasses = btnClasses + ' clone-parent btn-glitch';
  }
  if (glitch) {
    return (
      <button onClick={callback} className={btnClasses}>
        <span className={glitchClass1}>{title}</span>
        <span className={glitchClass2}>{title}</span>
      </button>
    );
  } else {
    return (
      <button onClick={callback} className={btnClasses}>
        <span>{title}</span>
      </button>
    );
  }
}
