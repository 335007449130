import React from 'react';
import { Button } from '../Components/Button';
import { useParams } from 'react-router-dom';
import { Loader } from '../Components/Loader';
import Filter from 'bad-words';
import { useForm } from 'react-hook-form';

const Form = ({ joinLobby, name, setName, email, setEmail, company, optionData, eventData, setCompany }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  let { id } = useParams();
  const lobbyId = id;

  const filter = new Filter();

  // console.log(eventData.acf.email_required);

  // var emailRequired = true;
  // if (eventData && eventData[0] && !eventData[0].email_req) {
  //   emailRequired = false;
  // }

  var companyRequired = true;
  if (eventData && eventData[0] && !eventData[0].company_req) {
    companyRequired = false;
  }

  const onSubmit = (data) => {
    joinLobby(name, email, company, lobbyId);
  };

  const isProfane = (string) => !filter.isProfane(string) || 'Name must not be profane';

  if (optionData.acf) {
    return (
      <div className="section container max-w-xs">
        {optionData.acf.data_collection.heading && (
          <h1 className="text-h2 text-center font-extrabold">{optionData.acf.data_collection.heading}</h1>
        )}
        {optionData.acf.data_collection.text && (
          <p className="text-xl text-center mt-1">{optionData.acf.data_collection.text}</p>
        )}
        <form className="space-y-4 mt-4" onSubmit={handleSubmit(onSubmit)}>
          <fieldset>
            <input
              className={`text-lg placeholder-hotpink ${
                errors.name?.type ? '!border-hotpink drop-shadow-hotpink-sm' : ''
              } `}
              id="name"
              type="text"
              {...register('name', {
                required: { value: true, message: 'Required' },
                validate: isProfane,
              })}
              placeholder={
                (errors.name?.type === 'required' ? 'This is a required field!' : '') ||
                (errors.name?.type === 'validate' ? 'Names cannot be profane!' : '')
              }
              onChange={(e) => {
                if (filter.isProfane(e.target.value)) {
                  e.target.value = null;
                } else {
                  setName(e.target.value);
                }
              }}
            />
            <label
              className={`uppercase text-sm ${errors.name?.type === 'required' ? 'text-hotpink' : ''}`}
              htmlFor="name"
            >
              Your name:
            </label>
          </fieldset>
          <fieldset>
            <input
              className={`text-lg placeholder-hotpink ${
                errors.email?.type ? '!border-hotpink drop-shadow-hotpink-sm' : ''
              } `}
              id="email"
              type="email"
              placeholder={
                (errors.email?.type === 'required' ? 'This is a required field!' : '') ||
                (errors.email?.type === 'pattern' ? 'Entered value does not match email format' : '')
              }
              {...register('email', {
                required: true,
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Entered value does not match email format',
                },
              })}
              aria-invalid={errors.email ? 'true' : 'false'}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <label
              className={`uppercase text-sm ${errors.email?.type === 'required' ? 'text-hotpink' : ''}`}
              htmlFor="name"
            >
              Email address:
            </label>
          </fieldset>
          <fieldset>
            <input
              className={`text-lg placeholder-hotpink border-hotpink ${
                errors.company?.type === 'required' ? '!border-hotpink drop-shadow-hotpink-sm' : ''
              } `}
              id="company"
              type="text"
              placeholder={errors.company?.type === 'required' ? 'This is a required field!' : ''}
              {...register('company', { required: companyRequired })}
              aria-invalid={errors.company ? 'true' : 'false'}
              onChange={(e) => {
                if (filter.isProfane(e.target.value)) {
                  e.target.value = null;
                } else {
                  setCompany(e.target.value);
                }
              }}
            />
            <label
              className={`uppercase text-sm ${errors.company?.type === 'required' ? 'text-hotpink' : ''}`}
              htmlFor="company"
            >
              Company name:
            </label>
          </fieldset>
          <fieldset>
            <div className="space-y-2">
              <div className="flex justify-center">
                <input
                  className={`mr-2 ${errors.legal1?.type === 'required' ? '!border-hotpink' : ''}`}
                  id="legal-1"
                  type="checkbox"
                  {...register('legal1', { required: true })}
                  aria-invalid={errors.legal1 ? 'true' : 'false'}
                />
                <label
                  className={`normal-case ${errors.legal1?.type === 'required' ? 'text-hotpink' : 'text-white'}`}
                  htmlFor="legal-1"
                >
                  I have read and understood the privacy policy.
                </label>
              </div>
              <div className="flex justify-center">
                <input
                  className={`mr-2 ${errors.legal1?.type === 'required' ? '!border-hotpink' : ''}`}
                  id="legal-2"
                  type="checkbox"
                  {...register('legal2', { required: true })}
                  aria-invalid={errors.legal2 ? 'true' : 'false'}
                />
                <label
                  className={`normal-case ${errors.legal2?.type === 'required' ? 'text-hotpink' : 'text-white'}`}
                  htmlFor="legal-2"
                >
                  I agree to receive emails from Brit. We will not share your email address with any third party.
                </label>
              </div>
            </div>
          </fieldset>
          <p className="text-center">
            <Button glitch="1" size="lg" classes="min-w-[80%] mx-auto mt-4" title="play game" />
          </p>
          {optionData.acf.data_collection.legal_links && (
            <footer className="flex justify-center space-x-2">
              <div className="bg-black divide-x">
                {optionData.acf.data_collection.legal_links.map((link, key) => (
                  <a href="{link.link.url}" className="px-2" target="_blank" rel="noreferrer" key={key}>
                    {link.link.title}
                  </a>
                ))}
              </div>
            </footer>
          )}
        </form>
      </div>
    );
  } else {
    return <Loader />;
  }
};

export default Form;
