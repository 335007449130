import { useEffect } from 'react';
import { Loader } from '../Components/Loader';

const Complete = ({ scoreTimer, rank, name, playerNumber, optionData }) => {
  useEffect(() => {
    if (scoreTimer.isRunning) {
      scoreTimer.handlePause();
    }
  }, [scoreTimer]);
  let text, shadow;
  if (playerNumber === 1) {
    shadow = 'drop-shadow-amethyst-sm';
    text = 'text-amethyst';
  } else if (playerNumber === 2) {
    shadow = 'drop-shadow-flamingo-sm';
    text = 'text-flamingo';
  } else if (playerNumber === 3) {
    shadow = 'drop-shadow-carnation-sm';
    text = 'text-carnation';
  }

  const date = new Date(scoreTimer.elapsedTime);

  const time = `0${date.getMinutes()}:${date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()}:${(
    '0' + Math.round(date.getMilliseconds())
  ).slice(-2)}`;

  const renderTime = () => {
    if (scoreTimer.elapsedTime >= 120000) {
      return '00:00:00';
    } else {
      return time;
    }
  };

  const getPositionString = () => {
    switch (rank) {
      case rank === '1st':
        return 'First';
      case rank === '2nd':
        return 'Second';
      case rank === '3rd':
        return 'Third';
      default:
        return rank;
    }
  };

  const footer = () => {
    if (optionData.acf) {
      return (
        <>
          {optionData.acf.completion.completion_cta_text && (
            <p className="text-xl text-white max-w-[270px] mx-auto mt-8 sm:mt-12">
              {optionData.acf.completion.completion_cta_text}
            </p>
          )}
          {optionData.acf.completion.completion_cta && (
            <a
              className="text-bold px-2 py-1 bg-black text-hotpink mt-4 sm:mt-6 inline-block"
              href={optionData.acf.completion.completion_cta.url}
              rel="noreferrer"
              target="_blank"
              title={optionData.acf.completion.completion_cta.title}
            >
              {optionData.acf.completion.completion_cta.title}
            </a>
          )}
        </>
      );
    } else {
      return <Loader />;
    }
  };

  let intro = 'better luck next time';
  if (rank === '1st') {
    intro = 'congratulations!';
  } else if (rank === 'lost') {
    intro = 'You better try again';
  }
  if (rank === 'lost') {
    return (
      <div className={`${text} section container max-w-xs text-center`}>
        <div className="space-y-2">
          <p className="text-lg uppercase font-medium">{intro}</p>
          <p className="text-h3 font-extrabold text-white">{name}</p>
          <p className="text-h3 font-medium">You ran out of time</p>
        </div>
        <p className={`${shadow} text-h1 text-stroke font-extrabold mt-4`}>game over</p>
        {footer()}
      </div>
    );
  } else {
    return (
      <div className={`${text} section container max-w-xs text-center`}>
        <div className="space-y-2">
          <p className="text-h5 font-medium">{intro}</p>
          <p className="text-h3 font-extrabold text-white">{name}</p>
          <p className="text-h3 font-medium">you finished in {rank}</p>
        </div>
        <p className={`${shadow} text-h1 text-stroke font-extrabold mt-4`}>{getPositionString()} place</p>
        <p className="!font-mono font-light text-h4 text-white mt-4">TIME: {renderTime()}</p>
        {footer()}
      </div>
    );
  }
};

export default Complete;
