import React from 'react';
// import { Navigation, Pagination, EffectFade } from 'swiper';
// import { Swiper, SwiperSlide } from 'swiper/react';

// import 'swiper/scss';
// import 'swiper/scss/effect-fade';
import { Button } from '../Components/Button';
import { Loader } from '../Components/Loader';
// import Instructions1 from '../img/instructions/instructions-1.png';
// import Instructions2 from '../img/instructions/instructions-2.png';
// import Instructions3 from '../img/instructions/instructions-3.png';

const Lobby = ({
  lobbyId,
  leaveLobby,
  optionData,
  playerNumber,
  players,
  host,
  updatePlayerstatus,
  startGame,
  setErrorMessage,
  setModalOpen,
  gameStatus,
  removePlayer,
}) => {
  const count = players.filter((player) => {
    if (player.status === 'ready') {
      return true;
    }

    return false;
  }).length;

  const handleUpdatePlayer = () => {
    updatePlayerstatus(lobbyId, currentPlayer.id, 'ready');
  };

  const handleStartGame = () => {
    if (players.length !== 3) {
      setModalOpen(true);
      setErrorMessage({
        heading: `are you sure you want to play a ${count} player game?`,
        copy: null,
        button: { text: 'PLAY', link: null },
        close: true,
        callback: () => {
          startGame(lobbyId);
          players.forEach((player) => {
            if (player.status !== 'ready') {
              removePlayer(player.id);
            }
          });
          setModalOpen(false);
        },
      });
    } else {
      startGame(lobbyId);
    }
  };

  const currentPlayer = players.find((p) => p.playerNumber === playerNumber);
  const playersReady = players.every((p) => p.status === 'ready');

  const MAX_PLAYERS = 3;

  const playersArray = Array.from({ length: MAX_PLAYERS }, (_, index) => {
    const existingPlayer = players[index];
    if (existingPlayer) {
      return existingPlayer;
    } else {
      return { playerNumber: index + 1, status: 'not-joined' };
    }
  });

  if (optionData.acf) {
    return (
      <div className="section container max-w-xs">
        <div className="lobby-wrapper">
          <h1 className="text-h2 text-center font-extrabold">Game lobby</h1>
          <h2 className="text-h4 text-center font-bold mt-6 sm:mt-10">How to play</h2>

          <ul className="text-center relative my-5 space-y-2">
            <li className="text-lg">Match the code.</li>
            <li className="text-lg">Complete all three levels.</li>
            <li className="text-lg">Make a mistake - start level again!</li>
            <li className="text-lg">Fastest time wins.</li>
          </ul>
          <p className="text-sm uppercase text-center mb-2">players</p>
          <ul className="player-status-list mx-auto flex justify-center items-center">
            {playersArray.map((player, key) => (
              <li
                className={`${player.status} player-color-${player.playerNumber} player-status mx-2 w-7 h-11 rounded-md border border-current flex justify-center items-center`}
                key={key}
                style={{ backgroundColor: `${player.status === 'ready' ? 'currentColor' : 'transparent'}` }}
              >
                <span>
                  {player.status === 'not-joined' && (
                    <span className="player-number text-md">{player.playerNumber}</span>
                  )}
                  {player.status === 'not-ready' && (
                    <svg className="w-3 h-3">
                      <use xlinkHref="#tick" />
                    </svg>
                  )}
                  {player.status === 'ready' && (
                    <svg className="w-3 h-3">
                      <use xlinkHref="#tickblack" />
                    </svg>
                  )}
                </span>
              </li>
            ))}
          </ul>

          <p className="text-center text-md mt-6">
            {currentPlayer && currentPlayer.playerNumber === 1 && (
              <span>Player {currentPlayer.playerNumber}, when player 2 and 3 are ready, start the game.</span>
            )}
            {currentPlayer && currentPlayer.playerNumber !== 1 && currentPlayer.status === 'not-ready' && (
              <span>Player {currentPlayer.playerNumber}, please confirm you're ready to play</span>
            )}
            {currentPlayer && currentPlayer.playerNumber !== 1 && currentPlayer.status === 'ready' && (
              <span>Player {currentPlayer.playerNumber}, the game will start soon</span>
            )}
          </p>

          <p className="text-center mt-2 sm:mt-6">
            {
              // If player 1
              currentPlayer.playerNumber === 1 && (
                <Button
                  callback={() => handleStartGame()}
                  size="lg"
                  glitch="2"
                  classes={`${playersReady ? 'active' : 'inactive'} min-w-[80%] mx-auto mt-2`}
                  title="Start Game"
                />
              )
            }
            {
              // If player 2 or 3
              host !== currentPlayer.id && currentPlayer.status !== 'ready' && (
                <Button
                  callback={currentPlayer.status !== 'ready' ? handleUpdatePlayer : null}
                  size="lg"
                  glitch="2"
                  classes="min-w-[80%] mx-auto mt-2"
                  title="Ready"
                />
              )
            }
          </p>
        </div>
      </div>
    );
  } else {
    return <Loader />;
  }
};

export default Lobby;
