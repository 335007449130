import { useEffect, useRef } from 'react';
import { Clone } from '../js/components/Clone';
import { Button } from '../Components/Button';
import { Video } from '../Components/Video';
import Header from '../Components/Global/Header';
import Footer from '../Components/Global/Footer';
import MainContent from '../Components/Global/MainContent';

function FourOhFour() {
  const clonedRef = useRef(false);
  const doClone = () => {
    Clone('js-404-clone', ['ani-glitch-split-4'], ['ani-glitch-split-3']);
  };
  useEffect(() => {
    if (clonedRef.current) return;
    clonedRef.current = true;
    doClone();
  }, []);

  return (
    <>
      <Video layer="bg" />
      <MainContent>
        <div className="main-content flex flex-col justify-between relative z-10">
          <Header gameStatus="landing" />
          <div className="section container container-expand text-center">
            <h1 className="text-h2 font-extrabold">error</h1>
            <div className="clone-parent">
              <h2 className="js-404-clone ani-glitch-split-3 animation-duration-10s text-double-shadow text-[48vw] font-extrabold leading-none">
                <span className="text-hotpink">404</span>
                <span className="text-sky">404</span>
                <span className="text-white">404</span>
              </h2>
            </div>
            <p className="text-2xl max-w-sm max-w-[270px] mx-auto">
              sorry, but it appears that this page doesn't exist
            </p>
            <Button glitch="2" size="lg" classes="mx-auto mt-8" title="ok" />
          </div>
          <Footer />
        </div>
      </MainContent>
      <Video layer="fg" />
    </>
  );
}

export default FourOhFour;
