// const textShadow = () => {
//   const shadowHotPinkSky = document.querySelectorAll('.js-clone');
//   console.log('textShadow = ' + shadowHotPinkSky.length);
//   if (shadowHotPinkSky.length > 0) {
//     console.log('length');
//     shadowHotPinkSky.forEach(function (item) {
//       console.log('cloen each');
//       const innerElem = item.querySelector('span:first-child');
//       const hotPinkClone = innerElem.cloneNode(true);
//       hotPinkClone.classList.add('text-shadow-hotpink-sky-clone-hotpink');
//       item.appendChild(hotPinkClone);

//       // const skyClone = item.querySelector('span:first-child').cloneNode(true);
//       // skyClone.classList.add('text-shadow-hotpink-sky-clone-sky');
//       // innerElem.after(skyClone);
//     });
//   }
// };

const Clone = (target, clonedClasses, removeClasses) => {
  const c = document.querySelectorAll('.' + target);
  c.forEach((item) => {
    const clone = item.cloneNode(true);
    // clone.className = '';
    clone.classList.add('cloned');
    clone.classList.add('pointer-events-none');
    if (clonedClasses) {
      clone.classList.add(...clonedClasses);
    }
    if (removeClasses) {
      clone.classList.remove(...removeClasses);
    }
    item.after(clone);
  });
};
export { Clone };
// export default Clone;
